import * as React from "react"
import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import {createNavItems} from "../utils/nav-items-util";
import {graphql} from "gatsby";
import SEO from "../components/seo";

type Props = {
  data: {
    prismicPrivacy: {
      uid: string
      url: string
      data: {
        privacy: {
          html: string
        }
      }
    }
  }
}

const PrivacyPage: React.FC<Props> = ({data}) => {
  return (
    <Layout navItems={createNavItems({current: ""})}>
      <SEO title="Privacy policy"/>
      <PageWrapper className={"mt-16 mb-16"}>
        <div className="prose" dangerouslySetInnerHTML={{__html: data.prismicPrivacy.data.privacy.html}}/>
      </PageWrapper>
    </Layout>
  )
};

export default PrivacyPage

export const query = graphql`
  query PrivacyPage {
    prismicPrivacy {
      uid
      url
      data {
        privacy {
          html
        }        
      }
    }
  }
`;
